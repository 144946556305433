import React from 'react'

import Container from './container'

const Footer = () => (
  <footer>
    <p><a href="https://paveljurasek.cz">Pavel Jurásek</a> &copy; 2020</p>
  </footer>
)

export default Footer
